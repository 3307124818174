.modal-lg {
	width: 90% !important;
}

.form-page {
	padding: 1em;
	max-height: 75vh;
	overflow-y: auto;
}

.form-page .data-grid-container {
	max-height: 25vh;
}

.form-page .full-height .data-grid-container {
	max-height: none;
}

.form-page .form-group {
	margin: .5em;
}

.form-page .col-sm-4 {
	padding-right: 0;
}

.form-page .control-label {
	display: block;
	font-weight: normal;
	font-size: .83em;
}

.form-page .required {
	color: red;
	font-weight: bold;
}

.form-page .label-value {
	display: inline-block;
	margin-top: .25em;
	font-size: 1.25em;
}

.button-right {
	float: right;
}
