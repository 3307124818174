.login-form {
	width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 8em;
}

.login-form label {
	width: 33%;
	text-align: left;
	font-weight: normal;
}

.login-form input {
	width: 67%;
}
