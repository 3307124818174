.data-grid-container {
	display: block;
	margin-bottom: .5em;
	overflow: auto !important;
	border-radius: 15px;
}

.data-grid {
	margin-bottom: 0 !important;
	font-size: .85em;
}

.data-grid a {
	/* 
	Used http://paletton.com/#uid=73c0u0krVDHhcM+mGH-BjxbBtoz
	to pick a blue that goes with the header green.
	*/
	color: #2765C0;
}

.data-grid-top-bar {
	width: 100%;
	background-color: #2C3E50;
	color: white;
	text-align: right;
	padding: .5em;
}

.data-grid th {
	position: sticky;
	top: 0;
	text-align: center;
}

.data-grid td {
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}

.data-grid .data-grid-header {
	background-color: #2C3E50;
	color: white;
	font-weight: bold;
	border: none;
	transition: .15s;
}
.data-grid .header-input {
	color: black;
	font-weight: normal;
}

.data-grid .data-grid-header:hover {
	background-color: rgb(80, 99, 117);
	cursor: pointer;
}

.data-grid-selected-row {
	background-color: rgba(24, 188, 155, 0.247) !important;
}
