#header{
	background: #18bc9c;
	padding: 1em;
}

#header img {
	position: relative;
	top: .5em;
	height: 4em;
}

#header #header-form {
	position: relative;
	top: .5em;
}

#header #header-search {
	margin-right: 1em;
}

#footer {
	padding: 1em;
	margin: 2em 0 0 0;
}
